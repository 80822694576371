import { env } from "../../config/EnvironmentConfig";

const initializeRazorpay = () => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const makePayment = async (studentData, totalCost, setPaymentResponse, setStepProcess) => {
  const res = await initializeRazorpay();
  const amount = totalCost * 100;

  if (!res) {
    alert('Razorpay SDK Failed to load');
    return;
  }

  try {
    let data;
    const response = await fetch(env.RAZORPAY_API_ENDPOINT_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount }),
    });

    if (!response.ok) {
      throw new Error(response.error);
    }

    data = await response.json();

    if (data.error) {
      alert(`Error: ${data.error}`);
      return;
    }

    const name = studentData.middleName
      ? `${studentData.firstName} ${studentData.middleName} ${studentData.lastName}`
      : `${studentData.firstName} ${studentData.lastName}`;

    const options = {
      key: process.env.RAZORPAY_KEY_ID,
      name: 'Stusphere',
      currency: data.currency,
      amount: data.amount,
      order_id: data.id,
      description: 'Thank you for your test donation Razorpay',
      image: '/images/logo-header.png',
      handler: function (response) {
        setPaymentResponse(response);
        window.scrollTo(0, 0);
        setStepProcess((step) => step + 1);
      },
      prefill: {
        name: name,
        email: `${studentData.emailAddress}`,
        contact: `${studentData.mobileNumber}`,
      },
      method: {
        netbanking: true,
        card: true,
        upi: true,
        wallet: true,
      },
      modal: {
        escape: false,
      },
      display: {
        blocks: {
          netbanking: true,
          card: true,
          upi: true,
          wallet: true,
        },
      },
    };

    if (!data.error) {
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      showsAlert(data.error)
    }
  } catch (error) {
    console.log("Razorpay error:", error)
  }

  function showsAlert(message, alertType) {
    // Create alert element
    const alertElement = document.createElement('div');
    alertElement.className = `alert alert-${alertType} alert-dismissible fade show fixed-top mx-auto mt-4`;
    alertElement.setAttribute('role', 'alert');
    alertElement.textContent = message;

    alertElement.style.width = '350px';

    document.body.appendChild(alertElement);

    setTimeout(() => {
      alertElement.remove();
    }, 5000);
  }

};
