import React from "react";
import SectionTitle from "../../components/ConsultancyElements/SectionTitle";

const PricingContainer = () => {
  const ServiceData = [
    {
      id: "1",
      img: "images/blog/1.jpg",
      authorImg: "images/blog/author-3.jpg",
      title: "Starter Plan",
      limit: "Up to 40 Students",
      price: "$39 per month",
    },
    {
      id: "2",
      img: "images/blog/2.jpg",
      authorImg: "images/blog/author-4.jpg",
      tag: "Consultant",
      date: "January 11, 2023",
      author: "Mellisa Obrian",
      title: "Standard Plan",
      limit: "Up to 100 Students",
      price: "$79 per month",
    },
    {
      id: "3",
      img: "images/blog/3.jpg",
      authorImg: "images/blog/author-5.jpg",
      title: "Plus Plan",
      limit: "Up to 300 Students",
      price: "$159 per month",
    },
    {
      id: "4",
      img: "images/blog/3.jpg",
      authorImg: "images/blog/author-5.jpg",
      title: "Enterprise",
      limit: "Up to 500 Students",
      price: "$199 per month",
    },
  ];

  return (
    <section className="services-section">
      <div className="container">
        <SectionTitle
          header="Our plans include all features"
          title="Attendance, Gradebook, Invoice, Admission, Student & Parent Portal"
        />
        <div className="inner-column">
          <div className="row clearfix">
            {ServiceData.map((data) => (
              <PricingBlock key={data.id} data={data} />
            ))}
          </div>
          <div className="row clearfix">
          <div className="container-lg-4 ">
      <div className="sec-title centered bg-white p-4">
        <h1>More than 500 students?</h1>
        <div className="col-lg-12 p-3">
          <div className="form-group mb-0">
            <input
              className="btn-submit "
              type="submit"
              value="Ask for a Quote"
              name="submit"
            />
          </div>
        </div>
      </div>
    </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const PricingBlock = ({ data }) => {
  const { title, limit, price } = data;
  return (
    <div className="news-block col-lg-3 col-md-3 col-sm-12">
      <div className="inner-box">
        <div className="lower-content">
          <div className="author-box">
          <div class="title">{title}</div>
          <span className="designation">{limit}</span>
          </div>
          <div className="lower-box">
            <h5>
              <a href="/blog-details">{price}</a>
            </h5>

            <a href="/blog-details" className="read-more">
              Get Started
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Features = () => {
  return (
    <div style={{ backgroundImage: `url(../images/background/28.jpg)`, overflow: 'hidden' }}>
    <div className="container about-section content-column col-lg-12 col-md-12 col-sm-12 px-lg-0" >
                            <div className="inner-column">
                                {/* <!-- Title Box --> */}
                                <div className="title-box">
                                    <h1>Features available on all plans</h1>
                                </div>
                                {/* <!-- End Title Box --> */}

                                {/* <!-- Feature Block --> */}
                                <div className="work-list d-md-flex align-items-center justify-content-between">
                                    <ul>
                                        <li><img src="images/icons/chevron-right.png" alt="img" />Academic Management</li>
                                        <li><img src="images/icons/chevron-right.png" alt="img" />Admissions Management</li>
                                        <li><img src="images/icons/chevron-right.png" alt="img" />Attendance management</li>
                                        <li><img src="images/icons/chevron-right.png" alt="img" />Teacher Portal</li>
                                        </ul>
                                    <ul>
                                        <li><img src="images/icons/chevron-right.png" alt="img" /> Notifications management</li>
                                        <li><img src="images/icons/chevron-right.png" alt="img" /> Financial Management</li>
                                        <li><img src="images/icons/chevron-right.png" alt="img" />Timetable Management</li>
                                        <li><img src="images/icons/chevron-right.png" alt="img" />Student and Teacher Assessments</li>
                                        
                                    </ul>
                                    <ul>  
                                       <li><img src="images/icons/chevron-right.png" alt="img" />Program Management</li> <li><img src="images/icons/chevron-right.png" alt="img" />Classroom Management</li>
                                        <li><img src="images/icons/chevron-right.png" alt="img" />Staff Management</li>
                                        <li><img src="images/icons/chevron-right.png" alt="img" />Student & Guardian Portal</li>
                                    </ul>
                                </div>

                            </div>
                        </div></div>
  );
};

const Pricing = () => {
  return (
    <>
      <section className="business-section-three pb-0">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(images/background/19.jpg)` }}
        ></div>
        <div className="">
          <PricingContainer />
          <Features />          
        </div>
      </section>
    </>
  );
};

export default Pricing;
