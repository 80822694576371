import { env } from "../config/EnvironmentConfig";
import axios from "axios";
 
const HttpRequestService = {};
 
const apiUrl = env.API_ENDPOINT_URL;
 
HttpRequestService.post = async function(endPoint, data) {
  try {
    const res = await axios.post(apiUrl + endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (error) {
    console.error('Error in post request:', error);
    throw error;
  }
};
 
HttpRequestService.get = async function(endPoint, data) {
  try {
    const res = await axios.get(apiUrl + endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (error) {
    console.error('Error in get request:', error);
    throw error;
  }
};
HttpRequestService.put = async function(endPoint, data) {
  try {
    const res = await axios.put(apiUrl + endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (error) {
    console.error('Error in get request:', error);
    throw error;
  }
};
 
export default HttpRequestService;