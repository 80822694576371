import HttpRequestService from "./HttpRequestService";
 
const StudentService = {};
 
const cntrlName = "student";
 
//Api for create
StudentService.create = function (data) {
  return HttpRequestService.post(cntrlName, data);
};
//Api for get data by id
StudentService.getById = function (id) {
  return HttpRequestService.get(cntrlName + "/" + id);
};

//Api for update
StudentService.update = function (id, data) {
  return HttpRequestService.put(cntrlName + "/" + id, data);
};
 
export default StudentService;