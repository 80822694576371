import React from "react";

const RegistrationSuccessScreen = () => {
  return (

    <div className="wp-block-group__inner-container">
      <ul className="wp-block-categories-list wp-block-categories ">
        <li className="cat-item">
          <div class="fancy-circle">
            <span>&#10003;</span>
          </div>
        </li>
        <li className="cat-item">
          <h3 className="full-width green">
            Registration Completed Successfully
          </h3>
        </li>
        <li className="cat-item mt-3">
          <p className="full-width">
            Please check your registered email for email verification.
          </p>
        </li>
        <li className="cat-item">
          <p className="full-width">
            Please complete the payment process to enroll in the course.
          </p>
        </li>
      </ul>
    </div>

  );
};

export default RegistrationSuccessScreen;
