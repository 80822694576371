import React, { useState, useEffect } from "react";
import { Select, DatePicker, Table, Popconfirm, message, Tooltip, Checkbox } from "antd";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import semesterListData from "../../assets/data/semester.data.json";
import segmentList from "../../assets/data/course-segment.data.json";

const CourseConfirmation = (props) => {
  const { groupId, selectedCourseList, setSelectedCourse, totalCost, setTotalCost } = props;
  const cost = 75;
  const credit = 0.5;

  useEffect(() => {
    // Calculate the total cost whenever the selectedCourseList changes
    const total = selectedCourseList.reduce(
      (sum, course) => sum + course.cost,
      0
    );
    setTotalCost(total);
  }, [selectedCourseList]);

  const handleSemesterChange = (value, record) => {
    setSelectedCourse((prevCourseData) =>
      prevCourseData.map((item) =>
        item.id === record.id
          ? {
            ...item,
            semesterId: value,
            credit: credit * (value === 3 ? 2 : 1),
            cost: cost * (value === 3 ? 2 : 1) * (item.isWaiver ? 0 : 1),
          }
          : item
      )
    );
  };

  const dateFormat = "MM/DD/YYYY";

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const handleDeleteCourse = (courseId) => {
    if (selectedCourseList.length === 1) {
      message.warning(
        "You cannot delete this course. At least one course should be required"
      );
      return false;
    }

    setSelectedCourse(
      selectedCourseList.filter((course) => course.id !== courseId)
    );
  };

  const handleWaiverChecked = (e, record) => {
    setSelectedCourse((prevCourseData) =>
      prevCourseData.map((item) =>
        item.id === record.id
          ? {
            ...item,
            isWaiver: e.target.checked,
            cost: cost * (e.target.checked ? 0 : 1) * (item.semesterId === 3 ? 2 : 1) * (item.segmentId === 3 ? 2 : 1),
          }
          : item
      )
    );
  };

  const columnsTable = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (item, record, index) => <span>{index + 1}</span>,
      type: 1,
    },
    {
      title: "Semester",
      dataIndex: "semester",
      key: "semester",
      render: (_, record) => (
        <Select
          defaultValue="Semester 1"
          onChange={(value) => handleSemesterChange(value, record)}
          options={semesterListData.map((semester) => ({
            label: semester.title,
            value: semester.id,
          }))}
        />
      ),
      type: 3,
    },
    {
      title: "Course",
      dataIndex: "title",
      key: "title",
      type: 1,
    },
    {
      title: "Segment",
      dataIndex: "segmentId",
      key: "segmentId",
      render: (_, record) => (
        <span>
          {segmentList.find((item) => item.id === record.segmentId)?.title}
        </span>
      ),
      type: 2,
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (_, record) => <span>$ {record.credit}</span>,
      type: 3,
    },
    {
      title: "Earliest Start Date",
      dataIndex: "earliestStartDate",
      key: "earliestStartDate",
      type: 3,
    },
    {
      title: "Preferred Start Date",
      dataIndex: "preferredStartDate",
      key: "preferredStartDate",
      render: (_, record) => (
        <DatePicker
          format={dateFormat}
          placeholder={dateFormat}
          disabledDate={disabledDate}
          allowClear={false}
          inputReadOnly
        />
      ),
      type: 3,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (_, record) => {
        if (record.id === "total") {
          // Render total cost in the last row
          return <span>$ {totalCost}</span>;
        }
        // Render individual course cost
        return <span>$ {record.cost}</span>;
      },
      type: 1,
    },
    {
      title: "Waiver / Stipend",
      dataIndex: "waiver",
      key: "waiver",
      render: (_, record) => (
        <Checkbox
          onClick={(e) => handleWaiverChecked(e, record)}
        />
      ),
      type: 1,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <div>
          <Popconfirm
            title="Delete the course"
            description="Are you sure to delete this course?"
            onConfirm={() => handleDeleteCourse(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip placement="bottom" title="Delete">
              <DeleteOutlined title="Delete" />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
      type: 1,
    },
  ].filter((item) => item.type === 1 || item.type === (groupId === 1 ? 2 : 3));

  return (
    <div className="title-box">
      <div className="title p-3">
        <h5>Selected Course List</h5>
      </div>

      {groupId > 1 ? (
        <h6 className="justified red-color p-2">Cost Per Semester - $ 75</h6>
      ) : null}

      <div className="form-group text-center">
        <Table
          columns={columnsTable}
          dataSource={selectedCourseList}
          pagination={false}
          rowKey={(record) => record.id}
          footer={() => (
            <div className="text-right">
              <strong className="m-5">Total: $ {totalCost}</strong>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default CourseConfirmation;
