import React from "react";
import SectionTitle from "../../components/ConsultancyElements/SectionTitle";

const FeatureContainer = () => {
  const FeatureData = [
    {
      id: "1",
      title: "Admission Management",
      description:
        "Streamlined admission management that digitizes, automates and upgrades all aspects of the admission process cycle.",
    },
    {
      id: "2",
      title: "Class Management",
      description:
        "Create an online representation of your classes, group students into classes, assign teachers and enjoy flexibility in all administrative tasks involved in class management.",
    },
    {
      id: "3",
      title: "Student Management",
      description:
        "Create student profiles and manage a long list of student-related processes, such as admission, performance, attendance and students’ financials, in a smart way.",
    },
    {
      id: "4",
      title: "Parent Portal",
      description:
        "Manage parent and guardian information effectively and authorize students' representatives to take on a more active role in their studies, through their portal.   ",
    },
    {
      id: "5",
      title: "Attendance Management",
      description:
        "Easily track and share attendance data, keeping administrators, teachers and parents in the loop.",
    },
    {
      id: "6",
      title: "School Student Fees",
      description:
        "Streamline your financial management, by monitoring all related transactions, such as student fees, installments and teachers’ payments, in one place.",
    },
    {
      id: "7",
      title: "Teacher Management",
      description:
        "Have all your teachers’ data gathered in one place. Easily assign teachers to classes and subjects, track payments and manage all related tasks with efficiency.",
    },
    {
      id: "8",
      title: "Academic Management",
      description:
        "Manage major academic tasks and processes, such as assessment creation and gradebooks, efficiently. Monitor class performance and share performance data with students and parents.",
    },
    {
      id: "9",
      title: "Class Scheduling",
      description:
        "Make lesson scheduling and school events planning a breeze. Get teachers, students and guardians in the game, with personalized timetable views, visible from their portals.",
    },
    {
      id: "10",
      title: "Student Portal",
      description:
        "Give your students access to their personalized dashboards and help them find useful information regarding their studies.",
    },
    {
      id: "11",
      title: "Teacher Portal",
      description:
        "Give your teachers access to their profile; help them efficiently manage their classes, their students, school-related tasks and processes such as keeping gradebooks, create assessments, track attendance and more.",
    },
  ];

  return (
    <section className="services-section">
      <SectionTitle
        header="Our Key Features"
      />
      <div className="row">
        {FeatureData.map((data) => (
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="news-block">
              <div className="inner-box">
                <div className="image">
                  <a href="/blog-details">
                    {/* <img src={img} alt="img" /> */}
                  </a>
                </div>
                <div className="lower-content">
                  <div className="author-box">
                    <div className="content">
                      <h5>
                        <a className="red-color" href="/blog-details">
                          {data.title}
                        </a>
                      </h5>
                    </div>
                  </div>
                  <div className="lower-box">
                    <div className="text">{data.description}</div>
                    <a href="/blog-details" className="read-more">
                      Read Article
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const Feature = () => {
  return (
    <>
      <section>
        <div className="container"></div>
        <div className="container">
          <FeatureContainer />
        </div>
      </section>
    </>
  );
};

export default Feature;
