import HttpRequestService from "./HttpRequestService";
 
const AuthService = {};

const cntrlName = "user/login";

// Api to authenticate user
AuthService.signIn = function (emailAddress, password) {
    const searchParams = new URLSearchParams();
    searchParams.append("emailAddress", emailAddress);
    searchParams.append("password", password);

    return HttpRequestService.get(
        `${cntrlName}?${searchParams}`
    );
}

export default AuthService;