import React, { useState } from "react";
import AuthService from "../../services/AuthService";
import { STATUS_CODE } from "../../constants/ApiConstant";
import { Divider, message } from "antd";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const LoginForm = (props) => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (!form.checkValidity()) {
      event.stopPropagation();
      setValidated(true);
      return false;
    }

    const emailFormat = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const email = form["email"].value;
    const password = form["password"].value;

    if (!email.match(emailFormat)) {
      message.warning("Please enter valid email");
      return false;
    }

    AuthService.signIn(email, password)
      .then((res) => {
        const response = res.data;
        if (response.statusCode === STATUS_CODE.SUCCESS && response.data.id) {
          message.success(`Login successfully`);
          props.setStep(3);
          localStorage.setItem("studenId", response.data.id); //To-do - need to change with student id
        } else {
          message.error(`Invalid email or password`);
        }
      })
      .catch(() => {
        message.error(`Error in authenticate student`);
      });
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="email">
          <Form.Label>Email*</Form.Label>
          <Form.Control
            className="login-text"
            type="text"
            placeholder="Email"
            pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter email
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="password">
          <Form.Label>Password*</Form.Label>
          <Form.Control
            className="login-text"
            type="password"
            placeholder="Password"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter password
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Divider></Divider>
      <div className="col-lg-12">
        <div className="form-group text-center">
          <input
            className="btn-submit login-btn"
            type="submit"
            value="Login"
            name="submit"
          />
        </div>
      </div>
    </Form>
  );
};

export default LoginForm;
