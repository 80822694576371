import HttpRequestService from "./HttpRequestService";

const CommonService = {};

//API for get all data
CommonService.getAllCountries = function () {
  return HttpRequestService.get("country");
};
//Api for get data by id
CommonService.getAllStateByCountryId = function (id) {
  return HttpRequestService.get("state/" + id);
};

export default CommonService;
