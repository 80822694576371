import React, { useState } from "react";
import AsideInfoBar from "../common/AsideInfoBar";
import MobileNav from "../common/MobileNav/MobileNav";
import { Link } from "react-router-dom";

const ConsultancyHeader = () => {
  const ConsultancyAsideData = {
    logo: "images/logo-header.png",
    href: "/consultancy",
  };

  // Sticky Header
  window.onscroll = () => {
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      document.querySelector("header").classList.add("sticky");
    } else {
      document.querySelector("header").classList.remove("sticky");
    }
  };

  // Aside info bar
  const [isAdd, setAdd] = useState("false");
  const handleAddCss = () => {
    setAdd(!isAdd);
  };

  // Search width increase
  const [isActive, setActive] = useState("true");

  const handleToggle = () => {
    setActive(!isActive);
  };

  return (
    <>
      <header className="main-header two">
        {/* <!-- Header Top --> */}
        <div className="header-top">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              {/* <!-- Left Box --> */}
              <div className="left-box d-flex align-items-center">
                {/* <!-- Social Box --> */}
                <ul className="social-box">
                  <li>
                    <a
                      href="https://www.facebook.com/"
                      className="fa fa-facebook-f"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/"
                      className="fa fa-instagram"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.twitter.com/"
                      className="fa fa-twitter"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/"
                      className="fa fa-linkedin"
                    ></a>
                  </li>
                </ul>
              </div>

              {/* <!-- Right Box --> */}
              <div className="right-box d-flex align-items-center">
                <ul className="info-list">
                  <li>
                    <a href="mailto:stusphere@gmail.com">
                      <span className="icon fa fa-envelope"></span>
                      stusphere@gmail.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon fa fa-map-marker"></span>231 Madison
                      Street, NewYork, USA
                    </a>
                  </li>
                </ul>

                {/* <!-- Button Box --> */}
                <div className="button-box d-lg-flex ">
                  <a
                    href={`${window.location.origin}/sis`}
                    rel=""
                    target="_blank"
                    className="btn clearfix login-btn"
                  >
                    <span className="btn-wrap">
                      <span className="text-one">Log In</span>
                      <span className="text-two">Log In</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Header Lower --> */}
        <div className="header-lower">
          <div className="container">
            <div className="inner-container d-flex justify-content-between align-items-center">
              <div className="logo-box">
                {/* <!-- Logo --> */}
                <div className="logo">
                  <a href="/">
                    <img src="/images/logo-header.png" alt="img" title="" />
                  </a>
                </div>
              </div>
              <div className="nav-outer d-flex align-items-center">
                {/* <!-- Main Menu --> */}
                <nav className="main-menu d-none d-lg-block">
                  <div className="navbar-collapse collapse clearfix">
                    <ul className="navigation clearfix">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="dropdown">
                        <Link to="/feature">Feature</Link>
                        <ul>
                          <li>
                            <Link to="/about">Student Management</Link>
                          </li>
                          <li>
                            <Link to="/blog-2">HR Management</Link>
                          </li>
                          <li>
                            <Link to="/faq">Payroll Management</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/pricing">Pricing</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                      <li>
                        <Link to="/enrollment">Admission</Link>
                      </li>
                    </ul>
                  </div>
                </nav>
                {/* <!-- Main Menu End--> */}

                {/* <!-- Responsive Menu --> */}
                <button
                  className="mobileMenu-toggle d-lg-none d-block ms-3"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasHome"
                  aria-controls="offcanvasHome"
                >
                  <i className="bi bi-list"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Header Lower --> */}
      </header>

      <AsideInfoBar
        handleAddCss={handleAddCss}
        isAdd={isAdd}
        data={ConsultancyAsideData}
      />
      <MobileNav />
    </>
  );
};

export default ConsultancyHeader;
