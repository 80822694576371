import HttpRequestService from "./HttpRequestService";
 
const WaiverService = {};
 
const cntrlName = "waiveoff-info";
 
//Api for get data by id
WaiverService.getByEmail = function (email) {
  return HttpRequestService.get("student" + "/" + cntrlName + "?Email=" + email);
};

export default WaiverService;
