import React from 'react';

const ConsultancyFooter = () => {
    return (
        <footer className="main-footer">
            <div className="container">

                {/* <!-- Upper Box --> */}
                <div className="upper-box">
                    <div className="row clearfix align-items-center">
                        {/* <!-- Logo Column --> */}
                        <div className="logo-column col-lg-3 col-md-12 col-sm-12">
                            <div className="logo"><a href="/"><img src="images/logo-footer.png" alt="img" title="" /></a></div>
                        </div>                        
                    </div>
                </div>

                {/* <!-- Widgets Section --> */}
                <div className="widgets-section">
                    <div className="row clearfix">

                        {/* <!-- Column --> */}
                        <div className="big-column col-lg-6 col-md-12 col-sm-12">
                            <div className="row clearfix">

                                {/* <!-- Footer Column --> */}
                                <div className="footer-column col-lg-7 col-md-6 col-sm-12">
                                    <div className="footer-widget about-widget">
                                        <h4>About Us</h4>
                                        <div className="text">Stusphere is a Business firm with record of winning many projects
                                            under tough circumstances.</div>
                                    </div>
                                </div>

                                {/* <!-- Footer Column --> */}
                                <div className="footer-column col-lg-5 col-md-6 col-sm-12">
                                    <div className="footer-widget links-widget">
                                        <h4>Company</h4>
                                        <ul className="links">
                                            <li><a href="/about">About Us</a></li>
                                            <li><a href="/contact">Contact Us</a></li>
                                            <li><a href="/termsAndPolicy">Terms & Policy</a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* <!-- Column --> */}
                        <div className="big-column col-lg-6 col-md-12 col-sm-12">
                            <div className="row clearfix">

                                {/* <!-- Footer Column --> */}
                                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget links-widget">
                                        <h4>Products</h4>
                                        <ul className="links">
                                            <li><a href="#">HRMS Payroll Software</a></li>
                                            <li><a href="#">Employee Finance</a></li>
                                            <li><a href="#">Leave Management Software</a></li>
                                            <li><a href="#">Online Payroll</a></li>
                                        </ul>
                                    </div>
                                </div>

                                {/* <!-- Footer Column --> */}
                                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget links-widget">
                                        <h4>contact us</h4>
                                        <ul className="contact-list">
                                            <li><span className="icon fa fa-phone"></span><a href="tel:+987-654-321-228-11">(+91) 999 999 9999</a></li>
                                            <li><span className="icon fa fa-envelope"></span><a href="mailto:stusphere@email.com">stusphere@email.com</a></li>
                                            <li><span className="icon fa fa-map-marker"></span>28 Street, New York City <br /> Untes States of America</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                {/* <!-- Footer Bottom --> */}
                <div className="footer-bottom">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="copyright">&copy; 2023 <a href="#">Stusphere</a>, All Rights Reserved</div>
                        </div>
                        <div className="right-box d-flex">
                            {/* <!-- Social Box --> */}
                            <ul className="social-box">
                                <li><a href="https://www.facebook.com/" className="fa fa-facebook-f"></a></li>
                                <li><a href="https://www.instagram.com/" className="fa fa-instagram"></a></li>
                                <li><a href="https://www.twitter.com/" className="fa fa-twitter"></a></li>
                                <li><a href="https://www.linkedin.com/" className="fa fa-linkedin"></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    );
};

export default ConsultancyFooter;